<div *ngIf="type === 'movil'">
  <div *ngIf="producto.variableData.contenido.oferta == 1"
    class="p-1 greenBg borderGreen text-white text-center rounded-7 ">
    <span class="fs-20px fw-bold">Oferta destacada</span>
  </div>
  <div
    ngClass="{{producto.variableData.contenido.oferta == 1 ? 'card border shadow rounded-7 border-4  p-4':'card border  rounded-7 shadow  p-4' }}">
    <h2 class="fs-20px text-black" *ngIf="cuantity == 1 ">{{cuantity}} Plan</h2>
    <h2 class="fs-20px text-black" *ngIf="cuantity !== 1 ">{{cuantity}} Planes</h2>
    <h2
      ngClass="{{producto.variableData.contenido.oferta == 1 ? 'fs-2  greenColor fw-bold' : 'fs-2   colorMagenta fw-bold'}}">
      <span class="fs-30px fw-bold">{{producto.productName}}</span>
    </h2>
    <div
      *ngIf="producto.variableData.descuento.porcentaje !== '0' && cuantity == 1 && !producto.variableData.descuento.moneda "
      ngClass="{{producto.variableData.contenido.oferta == 1 ?'d-flex align-items-center br-15 greenColor borderGreen border border-1 align-self-start my-3':'d-flex align-items-center my-3 mx-0 br-15 colorMagenta borderMagenta align-self-start' }}">
      <span
        ngClass="{{producto.variableData.contenido.oferta == 1 ? 'greenBg text-white br-15 px-3 fs-6 p-1 fw-bold' : 'bgMagenta text-white br-15 px-3 fs-18 p-2 fw-bold'}}">{{(producto.variableData.descuento.porcentaje
        ===
        'Web' ? 'DCTO. EXCLUSIVO WEB':producto.variableData.descuento.porcentaje + ' %')}}
      </span>
      <span
        ngClass="{{producto.variableData.contenido.oferta == 1 ? 'rounded-end px-2 fs-18 fw-bold greenColor' : 'rounded-end px-2 fs-18 fw-bold colorMagenta'}}"
        *ngIf="producto.variableData.descuento.porcentaje !== 'Web'">Dcto x
        {{producto.variableData.descuento.duracion}}</span>
    </div>
    <div
      *ngIf="producto.variableData.descuento.porcentaje !== '0' && cuantity == 1 && producto.variableData.descuento.moneda"
      ngClass="{{producto.variableData.contenido.oferta == 1 ?'d-flex align-items-center br-15 greenColor borderGreen border border-1 align-self-start my-3':'d-flex align-items-center my-3 mx-0 br-15 colorMagenta borderMagenta align-self-start' }}">
      <span
        ngClass="{{producto.variableData.contenido.oferta == 1 ? 'greenBg text-white br-15 px-3 fs-6 p-1 fw-bold' : 'bgMagenta text-white br-15 px-3 fs-18 p-2 fw-bold'}}">{{(producto.variableData.descuento.porcentaje
        ===
        'Web' ? 'DCTO. EXCLUSIVO WEB':"$" + producto.variableData.descuento.moneda + ' dcto x 6 meses')}}
      </span>
    </div>
    <h5 class="fs-42px fw-bolder text-black mt-3" *ngIf="cuantity == 1 && producto.offerAmount">
      {{producto.offerAmount|currencyFormat}}
      <span class="fs-20px"
        *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web' ">/mes</span>
      <span class="fs-20px"
        *ngIf="producto.category === '3' && producto.variableData.descuento.porcentaje !== 'Web'">c/u</span>
      <span class="fs-20px" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
        {{producto.variableData.descuento.duracion}}</span>
    </h5>
    <div class=" fs1remDesktop d-flex flex-column align-items-center text-black mt-3" *ngIf=" cuantity !== 1">
      <div class="d-flex align-items-center justify-content-between w-100">
        <span class="">Plan</span>
        <span class="">{{producto.amount|currencyFormat}}</span>
      </div>
    </div>
    <div class=" fs1remDesktop d-flex flex-column align-items-center text-black mt-3" *ngIf=" cuantity !== 1">
      <div class="d-flex align-items-center justify-content-between w-100">
        <span class="">Plan adicional {{cuantity - 1}}</span>
        <span *ngIf="producto.id == '1'" class="">$4.990</span>
        <span *ngIf="producto.id !== '1'" class="">$2.990</span>
      </div>
    </div>
    <div class=" fs1remDesktop d-flex flex-column align-items-center text-black mt-3" *ngIf="cuantity !== 1">
      <div class="d-flex align-items-center justify-content-between w-100">
        <span class="fw-bold text-black fs-5">{{cuantity}} planes x</span>
        <span class="fw-bold">{{calcularPrecioTotal(producto,cuantity,'cu') + " c/u"}}</span>
      </div>
      <div class="align-self-end d-flex flex-column align-items-end">
        <span class="grayColor">Total {{calcularPrecioTotal(producto,cuantity,'offer')}}</span>
        <span class="grayColor">Desde mes 7, {{calcularPrecioTotal(producto,cuantity,'plan')}}</span>
      </div>
    </div>
    <h5 class="fs-1 fw-bolder text-black mt-3" *ngIf="producto.offerAmount == null && cuantity == 1">{{producto.amount |
      currencyFormat}}
      <span class="fs-6" *ngIf="producto.category !== '3'">/mes</span>
    </h5>
    <h6 class="grayColor fs-20px" *ngIf="cuantity == 1 "> Luego {{producto.amount |
      currencyFormat}}
      <span class="" *ngIf="producto.category !== '3'">/mes</span>
      <span class="" *ngIf="producto.category == '3'">al mes</span>
    </h6>


    <a *ngIf="cuantity == 1" [href]="producto.link"
      ngClass="{{producto.variableData.contenido.oferta == 1 ? 'btn btn-hover text-white rounded-3 p-2 my-3 text-capitalize fs-2 ' : 'btn btn-hoverMagenta text-white rounded-3 p-2 my-3 text-capitalize '}}"
      id='{{producto.productName.includes("2")?2+producto.productName.slice(8):producto.productName.slice(6)}}'>Obtener
      descuento
    </a>
    <a *ngIf="cuantity > 1" [href]="producto.variableData.contenido.link"
      ngClass="{{producto.variableData.contenido.oferta == 1 ? 'btn btn-hover text-white rounded-3 p-2 my-3 text-capitalize ' : 'btn btn-hoverMagenta text-white rounded-3 p-2 my-3 text-capitalize '}}"
      id='{{producto.productName.includes("2")?2+producto.productName.slice(8):producto.productName.slice(6)}}'>Obtener
      descuento
    </a>

    <div class="d-block">
      <a class="fs-14 mb-2 fw-bold text-black dropdown-toggle" data-mdb-toggle="collapse"
        href="#collapse{{producto.id}}" role="button" aria-expanded="false" aria-controls="collapse{{producto.id}}">
        Incluye
      </a>

      <div id="collapse{{producto.id}}" class="collapse ">
        <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
          <p class="m-0 fs-14px">Gigas {{getNumberGigas(producto.variableData.contenido.gigas)}} para navegar en alta
            velocidad</p>
        </div>
        <!-- <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center">
            <img class="me-2" src="../../../assets/img/Iconos Landing/MovistarTV.svg" height="40" width="40">
            <p class="m-0 fs-14px">TV en tu celular con Movistar Tv Free</p>
          </div> -->
        <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Minutos.svg" height="40" width="40">
          <p class="m-0 fs-14px">Minutos {{getNumberMinutos(producto.variableData.contenido.minutos)}}
            + {{getNumberSMS(producto.variableData.contenido.sms)}} SMS</p>
        </div>
        <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center"
          *ngIf="producto.variableData.contenido.datos">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Logo Roaming.svg" height="40" width="40">
          <p class="m-0 fs-14px">Roaming: Datos ilimitado por {{producto.variableData.contenido.roaming}} días</p>
        </div>
        <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center"
          *ngIf="producto.variableData.contenido.roaming && !producto.variableData.contenido.datos">
          <img class="me-2" src="../../../assets/img/Iconos Landing/roamingwhatsapp.svg" height="40" width="40">
          <p class="m-0 fs-14px">Roaming: Whatsapp ilimitado por {{producto.variableData.contenido.roaming}} días</p>
        </div>
        <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center"
          *ngIf="producto.variableData.contenido.rrss">
          <img class="me-2" src="../../../assets/img/Iconos Landing/roamingwhatsapp.svg" height="40" width="40">
          <p class="m-0 fs-14px">Roaming: Whatsapp ilimitado por {{producto.variableData.contenido.roaming}} días</p>
        </div>
      </div>
    </div>

  </div>
</div>




<!-- Caluga Móvil Planes Para Todos -->
<!-- <div class="card border rounded-7 p-4" *ngIf="type === 'movilPlanParaTodos'">
  <h2 class="h4 fw-bold">Plan Para Todos</h2>
  <h4 class="colorMovistar h2 fw-bold">{{producto.productName}}</h4>
  <div class="row d-inline-block my-3 mx-0 rounded-4 p-1">
    <span class="bgMagenta text-white rounded-start p-2 mx-0 fw-bold " [ngClass]="{'rounded-end': producto.variableData.descuento.porcentaje ===
    'Web'}">{{(producto.variableData.descuento.porcentaje ===
      'Web' ? 'DCTO. EXCLUSIVO WEB':producto.variableData.descuento.porcentaje + ' %')}}</span>
    <span class="colorMagenta borderMagenta rounded-end p-2 mx-0"
      *ngIf="producto.variableData.descuento.porcentaje !== 'Web'">Dcto x
      {{producto.variableData.descuento.duracion}}</span>
  </div>
  <h4 class="fw-bold" *ngIf="producto.offerAmount !== null">{{producto.offerAmount | currencyFormat}}
    <span class="h6 fw-light" *ngIf="producto.category !== '3'"> el primer mes</span>
    <span class="h6 fw-light"
      *ngIf="producto.category  == '3'  && producto.variableData.descuento.porcentaje !== 'Web' ">c/u x
      {{producto.variableData.descuento.duracion}}</span>
    <span class=" h6" *ngIf="producto.category === '3' && producto.variableData.descuento.porcentaje === 'Web' "> c/u x
      {{producto.variableData.descuento.duracion}}</span>
  </h4>
  <h4 class="fw-bold" *ngIf="producto.offerAmount === null">{{producto.amount | currencyFormat}}
    <span class="h6 fw-light" *ngIf="producto.category !== '3'"> /mes</span>
  </h4>
  <h6 class="fw-light" *ngIf="producto.offerAmount === null">&nbsp;</h6>
  <h6 class="fw-light" *ngIf="producto.offerAmount !== null">Luego {{producto.amount | currencyFormat}}
    <span class="h6 fw-light" *ngIf="producto.category !== '3'"> /mes</span>
    <span class="h6 fw-light" *ngIf="producto.category == 3">precio normal/mes</span>
  </h6>

  <a [href]="producto.link" class="btn btn-hover text-white rounded-3 p-2 my-3 text-capitalize"
    id={{getId(producto)}}>Comprar</a>

  <p class="fs-14 m-0 mb-2">Detalles del plan</p>

  <div class="row d-flex justify-content-center">
    <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center">
      <img class="me-2" src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
      <p class="m-0 fs-14px">{{getNumberGigas(producto.variableData.contenido.gigas)}} GB con cobertura 4G en todo Chile
      </p>
    </div>
    <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center">
      <img class="me-2" src="../../../assets/img/Iconos Landing/Minutos.svg" height="40" width="40">
      <p class="m-0 fs-14px">{{getNumberMinutos(producto.variableData.contenido.minutos)}} Minutos para estar simpre
        comunicado </p>
    </div>
    <div class="col-12 mb-3 p-0 d-flex jusitfy-content-center align-items-center"
      *ngIf="producto.variableData.contenido.roaming">
      <img class="me-2" src="../../../assets/img/Iconos Landing/Logo Roaming.svg" height="40" width="40">
      <p class="m-0 fs-14px">RRSS Libres</p>
    </div>
  </div>

</div> -->

<!-- Caluga Hogar -->
<div class="card border shadow rounded-7 p-4" *ngIf="type === 'hogar' && !producto.variableData.contenido.oferta">
  <div
    class="d-flex flex-row flex-md-column  align-items-center align-items-md-start w-100 justify-content-between gap-2 gap-md-0"
    *ngIf="producto.category == 4 && !producto.variableData.contenido.cien">
    <h2 class="text-black fs-5 mb-0">Internet fibra óptica</h2>
    <div class="d-flex gap-2 align-items-center justify-content-center">
      <span class="fs-2 text-decoration-line-through me-1"
        *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}</span>
      <span class="fs-42px text-black fw-bold">{{producto.productName}}</span>
    </div>
  </div>
  <div
    class="d-flex flex-row flex-md-column align-items-center align-items-md-start w-100  justify-content-between gap-md-0"
    *ngIf="producto.category == 20 && producto.variableData.contenido.cien === 1 ">
    <h2 class="text-black fs-5 mb-0">Pack Dúo</h2>
    <div class="d-flex align-items-center">
      <span class="fs-5 text-decoration-line-through me-1"
        *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}</span>
      <span class="fs-42px text-black fw-bold">800 Megas</span>
    </div>
  </div>
  <div class="d-flex flex-row flex-md-column align-items-center align-items-md-start w-100 gap-2 gap-md-0 mb-3"
    *ngIf="producto.category == 16 || producto.category == 15 || producto.category == 20 && producto.variableData.contenido.cien !== 1 ">
    <h2 class="text-black fs-5 mb-0">Pack Dúo</h2>
    <div class="d-flex align-items-center">
      <span class="fs-5 text-black text-decoration-line-through me-1"
        *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}
      </span>
      <span class="fs-42px text-black fw-bold">{{producto.productName}}</span>
    </div>
    <span>
      <span class="fs-5 mt-0 mb-2 text-black">+ TV por Deco</span>
    </span>

  </div>
  <div *ngIf="producto.variableData.id !== 87">
    <div *ngIf="(producto.category == 16 || producto.category == 15 || producto.category == 20)">
      <div class="d-flex flex-column align-items-start">
        <div class="d-flex align-items-center gap-3  w-100">
          <span class="fs-6 text-black">Tu plan incluye </span>
          <div class="d-flex align-items-center ">
            <img class=" me-2 rounded-6" src="../../../assets/img/maxlogo.png" height="40" width="40">
            <img class=" me-2  rounded-6" src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
            <img class="rounded-6" src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40" width="40">
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div *ngIf="producto.category == 20" class="separator ">
  </div>
  <!-- <div *ngIf="producto.category == 20  " class="d-flex flex-column gap-2 w-100 mb-3 ">
    <div *ngIf="producto.variableData.agregado" class="d-flex align-items-center gap-2 ">
      <div class="form-check  " *ngIf="producto.variableData.agregado">
        <input class="form-check-input" type="checkbox" (change)="onSelect($event.target.checked)" id={{producto.id}}>
      </div>
      <div class="d-flex flex-column">
        <label class="fs-6">
          Cambia a TV con decodificador

        </label>
        <span class="text-black fs-14px grayColor">
          Disfruta la máxima calidad en tu TV </span>
      </div>
    </div>
  </div> -->
  <div class="d-flex flex-column flex-md-column align-items-start w-100 gap-2 gap-md-0"
    *ngIf="producto.category == 17 || producto.category == 18 || producto.category == 21 ">
    <h2 class="text-black fs-5 mb-0">Pack Trío</h2>
    <div class="d-flex align-items-center">
      <span class="fs-5 text-black text-decoration-line-through me-1"
        *ngIf="producto.id == 85">{{producto.variableData.descuento.valor}}
      </span>
      <span class="fs-5 text-black text-decoration-line-through me-1"
        *ngIf="producto.id !== '85' && producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}
      </span>
      <span class="fs-42px text-black fw-bold">{{producto.productName}}</span>
    </div>
    <span class="fs-5 mt-0 mb-2 text-black">+ TV por Deco
      <span *ngIf="producto.category == 21">+
        Telefonía</span>
    </span>
  </div>
  <div *ngIf="producto.category == 17 || producto.category == 18 || producto.category == 21 "
    class="d-flex align-items-center gap-3 w-100">
    <div class="d-flex flex-column align-items-start">
      <div class="d-flex align-items-center gap-3  w-100">
        <span class="fs-14px text-black">Tu plan incluye</span>
        <div class="d-flex align-items-center ">

          <img class=" me-2 rounded-6" src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
          <img class="me-2 rounded-6" src="../../../assets/img/maxlogo.png" height="40" width="40">
          <img class="rounded-6" src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40" width="40">

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="producto.category == 21" class="separator ">
  </div>
  <!-- <div *ngIf="producto.category == 21  " class="d-flex flex-column gap-2 w-100 mb-3 ">
    <div class="d-flex align-items-center gap-2 ">
      <div class="form-check  " *ngIf="producto.variableData.agregado">
        <input class="form-check-input" type="checkbox" (change)="onSelect($event.target.checked)" id={{producto.id}}>
      </div>
      <div class="d-flex flex-column">
        <label class="fs-6">
          Cambia a TV con decodificador

        </label>
        <span class="text-black fs-14px grayColor">
          Disfruta la máxima calidad en tu TV </span>
      </div>
    </div>
  </div> -->
  <div class="d-flex flex-md-column flex-row-reverse align-items-center align-items-md-start justify-content-between">
    <div *ngIf="!producto.variableData.agregado"
      class="d-flex align-items-center br-15 text-dark borderMagenta border border-1 ">
      <span
        class="bgMagenta text-white br-15 px-md-3 px-2 fs-14px p-1 fw-bold ">{{(producto.variableData.descuento.porcentaje
        ===
        'Web' ? 'DCTO. EXCLUSIVO WEB':producto.variableData.descuento.porcentaje + ' %')}}
      </span>
      <span class="rounded-end px-md-2 px-2 fs-14px fw-bold colorMagenta "
        *ngIf="producto.variableData.descuento.porcentaje !== 'Web'"> x
        {{producto.variableData.descuento.duracion}}</span>
    </div>
    <div *ngIf="producto.variableData.agregado"
      class="d-flex align-items-center br-15 text-dark borderMagenta border border-1 ">
      <span class="bgMagenta text-white br-15 px-md-3 px-2 fs-14px p-1 fw-bold ">{{
        producto.variableData.agregado.descuento + '
        %' }}
      </span>
      <span class="rounded-end px-md-2 px-2 fs-14px fw-bold colorMagenta "
        *ngIf="producto.variableData.descuento.porcentaje !== 'Web'&& searchActiveCheckbox(producto.id) == false "> x
        {{producto.variableData.descuento.duracion}}</span>
      <span class="rounded-end px-md-2 px-2 fs-14px fw-bold colorMagenta "
        *ngIf="producto.variableData.descuento.porcentaje !== 'Web'&& searchActiveCheckbox(producto.id) ">
        x
        {{producto.variableData.agregado.duracion ? producto.variableData.agregado.duracion :
        producto.variableData.descuento.duracion}}</span>
    </div>
    <div class="">

      <h5 class="fs-42px fw-bolder text-black mt-3"
        *ngIf="producto.offerAmount !== null && producto.variableData.agregado ">
        {{producto.variableData.agregado.offer|currencyFormat}}
        <span class="fw-medium fs-20px"
          *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web'">/mes</span>
        <span class="" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
          {{producto.variableData.descuento.duracion}}</span>
      </h5>

      <h5 class="fs-42px fw-bolder text-black mt-3"
        *ngIf="producto.offerAmount !== null && !producto.variableData.agregado">
        {{producto.offerAmount|currencyFormat}}
        <span class="fw-medium fs-20px"
          *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web'">/mes</span>
        <span class="" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
          {{producto.variableData.descuento.duracion}}</span>
      </h5>

      <h5 class="fs-6 grayColor" *ngIf="producto.offerAmount === null && !producto.variableData.agregado">
        {{producto.amount |
        currencyFormat}}
        <span class="fs-6" *ngIf="producto.category !== '3'">/mes</span>
      </h5>
      <h6 class="fs-6 grayColor" *ngIf="producto.offerAmount !== null  && !producto.variableData.agregado  "> Luego
        {{producto.amount
        |
        currencyFormat}}
        <span class="fs-6" *ngIf="producto.category !== '3'">/mes</span>
      </h6>

      <h6 class="fs-6 grayColor"
        *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id) == false ">
        Luego {{producto.amount | currencyFormat}}
        <span class="" *ngIf="producto.category !== '3'">/mes</span>
      </h6>
      <h6 class="fs-6 grayColor"
        *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id)">
        Luego
        {{producto.variableData.agregado.amount | currencyFormat}}
        <span class="" *ngIf="producto.category !== '3'">/mes</span>
      </h6>
    </div>
  </div>

  <div class="d-flex flex-row-reverse flex-md-column justify-content-between align-items-center w-100">

    <a *ngIf="producto.category !== '21' && producto.category !== '20' " [href]="producto.link"
      class="btn btn-hover text-white rounded-7 p-2 my-3 text-capitalize fs-3 fw-bold w-responsive w-100 "
      id={{getId(producto)}}>Ver
      factibilidad
    </a>
    <a *ngIf=" producto.variableData.agregado  && producto.category == 21 || producto.category == 20 "
      [href]=" producto.variableData.agregado.utm"
      class="btn btn-hover text-white rounded-7 p-2 my-3 text-capitalize fs-3 fw-bold w-responsive"
      id={{getId(producto)}}>Ver
      factibilidad
    </a>
    <a class="fs-14 d-md-none d-block  fw-bold text-black dropdown-toggle borderGreen border p-1 rounded-7 px-4 text-center"
      data-mdb-toggle="collapse" href="#collapse{{producto.id}}" role="button" aria-expanded="false"
      aria-controls="collapse{{producto.id}}">
      Incluye
    </a>


  </div>
  <div class="d-block d-md-none">
    <div id="collapse{{producto.id}}" class="collapse ">
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3">
        <img class="me-2" src="../../../assets/img/Iconos Landing/internet 1.svg" height="40" width="40">
        <p class="m-0 fs-14px">Velocidad Simétrica de bajada y subida: Hasta
          {{producto.variableData.contenido.beneficio}} Mbps.</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.instalacion == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg" height="40" width="40">
        <p class="m-0 fs-14px"> Instalacion sin costo</p>
      </div>
      <!-- <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.appfree == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg" height="40" width="40">
        <p class="m-0 fs-14px">APP Smart WiFi</p>
      </div> -->
      <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.integra == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Router 1.svg" height="40" width="40">
        <div>
          <p class="m-0 fs-14px ">Incluye IntegraHub WIFI 6</p>

        </div>
      </div>
      <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.extensor == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/repetidor_azul_x2.svg" height="40" width="40">
        <div>
          <p class="m-0 fs-14px ">Con extensor WIFI</p>
          <p class="m-0 fs-14px grayColor">Si tu hogar lo requiere</p>
        </div>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.canales > 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
        <p class="m-0 fs-14px">{{producto.variableData.contenido.canales}} Canales (75 HD) + 8 abiertos (7 HD)</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.deco == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/decodificador.svg" height="40" width="40">
        <p class="m-0 fs-14px">1 decodificador incluido con Movistar TV</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.minutos == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/telefonoMinutos.svg" height="40" width="40">
        <p class="m-0 fs-14px">Minutos Fijos Ilimitados y 300 minutos a móviles</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.netflix == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40" class="rounded-7">
        <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.star == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/television.svg" height="40" width="40">
        <p class="m-0 fs-14px"><span class="colorMovistar">101 canales (89HD + 9 abiertos)</span></p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.star == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Router.svg" height="40" width="40">
        <p class="m-0 fs-14px">Router IntegraHub</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.paramount == 1">
        <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
      </div>
    </div>
  </div>
  <div class="d-none d-md-block">
    <div class="row d-flex justify-content-center">
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3">
        <img class="me-2" src="../../../assets/img/Iconos Landing/internet 1.svg" height="40" width="40">
        <p class="m-0 fs-14px">Velocidad Simétrica de bajada y subida: Hasta
          {{producto.variableData.contenido.beneficio}} Mbps.</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.instalacion == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg" height="40" width="40">
        <p class="m-0 fs-14px"> Instalacion sin costo</p>
      </div>
      <!-- <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.appfree == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg" height="40" width="40">
        <p class="m-0 fs-14px">APP Smart WiFi</p>
      </div> -->
      <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.integra == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Router 1.svg" height="40" width="40">
        <div>
          <p class="m-0 fs-14px ">Incluye IntegraHub WIFI 6</p>

        </div>
      </div>
      <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.extensor == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/repetidor_azul_x2.svg" height="40" width="40">
        <div>
          <p class="m-0 fs-14px ">Con extensor WIFI</p>
          <p class="m-0 fs-14px grayColor">Si tu hogar lo requiere</p>
        </div>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.canales > 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
        <p class="m-0 fs-14px">{{producto.variableData.contenido.canales}} Canales (75 HD) + 8 abiertos (7 HD)</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.deco == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/decodificador.svg" height="40" width="40">
        <p class="m-0 fs-14px">1 decodificador incluido con Movistar TV</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.minutos == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/telefonoMinutos.svg" height="40" width="40">
        <p class="m-0 fs-14px">Minutos Fijos Ilimitados y 300 minutos a móviles</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.netflix == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40" class="rounded-7">
        <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.star == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/television.svg" height="40" width="40">
        <p class="m-0 fs-14px"><span class="colorMovistar">101 canales (89HD + 9 abiertos)</span></p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.star == 1">
        <img class="me-2" src="../../../assets/img/Iconos Landing/Router.svg" height="40" width="40">
        <p class="m-0 fs-14px">Router IntegraHub</p>
      </div>
      <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
        *ngIf="producto.variableData.contenido.paramount == 1">
        <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
      </div>
    </div>
  </div>
</div>


<!-- Caluga Hogar oferta -->
<div class=" d-flex  flex-column " *ngIf="type === 'hogar' && producto.variableData.contenido.oferta == 1">
  <div class="p-1 greenBg borderGreen rounded text-white text-center rounded-7 ">
    <span class="fs-20px fw-bold">Oferta Destacada</span>
  </div>
  <div class="card   shadow rounded-7 p-4">
    <div
      class="d-flex flex-row flex-md-column align-items-center align-items-md-start justify-content-between w-100 gap-2 gap-md-0"
      *ngIf="producto.category == 4 && !producto.variableData.contenido.cien">
      <h2 class="text-black fs-5 mb-0">Internet fibra óptica</h2>
      <div>
        <span class="fs-2 text-decoration-line-through me-1 greenColor"
          *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}</span>
        <span class="fs-42px greenColor fw-bold">{{producto.productName}}</span>
      </div>
    </div>
    <div
      class="d-flex flex-row flex-md-column align-items-center align-items-md-start justify-content-between w-100 gap-2 gap-md-0"
      *ngIf="producto.category == 4 && producto.variableData.contenido.cien === '1'">
      <div>

        <h2 class="text-black fs-5 mb-0">Internet fibra óptica</h2>
        <span class="fs-2 text-decoration-line-through me-1 greenColor"
          *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}</span>
        <span class="fs-42px greenColor fw-bold">600 Megas</span>
      </div>
    </div>
    <div
      class="d-flex flex-row flex-md-column align-items-center align-items-md-start justify-content-between w-100 gap-2 gap-md-0"
      *ngIf="producto.category == 16 || producto.category == 15 || producto.category == 20 ">
      <h2 class="text-black fs-5 mb-0">Pack Dúo</h2>
      <span class="fs-42px text-black text-decoration-line-through me-1"
        *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}
      </span>
      <span class="fs-42px text-black fw-bold">{{producto.productName}}</span>
    </div>
    <div *ngIf="producto.category == 16 || producto.category == 15 || producto.category == 20 "
      class="d-flex align-items-center my-2 gap-3 w-100">
      <div class="d-flex flex-column align-items-start">
        <div class="d-flex align-items-center gap-2 w-100 ">
          <label class="fs-14px">
            Agrega Disney+
            por $2.000
          </label>
          <img *ngIf="producto.variableData.agregado" class="mx-1 rounded-6"
            src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
          <div class="form-check  " *ngIf="producto.variableData.agregado">
            <input class="form-check-input" type="checkbox" (change)="onSelect($event.target.checked)"
              id={{producto.id}}>
          </div>
        </div>

        <div class="d-flex align-items-center gap-3  w-75">
          <span class="fs-14px text-black">Incluye</span>
          <div class="d-flex align-items-center ">

            <img class=" me-2 rounded-6" src="../../../assets/img/Iconos Landing/Icon_star.svg" height="40" width="40">
            <img class=" rounded-6" src="../../../assets/img/maxlogo.png" height="40" width="40">
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-md-column align-items-center align-items-md-start w-100 gap-2 gap-md-0"
      *ngIf="producto.category == 17 || producto.category == 18 || producto.category == 21 ">
      <h2 class="text-black fs-5 mb-0">Pack Trío</h2>
      <span class="fs-1 text-black text-decoration-line-through me-1"
        *ngIf="producto.variableData.descuento.valor">{{producto.variableData.descuento.valor}}
      </span>
      <span class="fs-1 text-black fw-bold">{{producto.productName}}</span>
    </div>
    <div *ngIf="producto.category == 17 || producto.category == 18 || producto.category == 21 "
      class="d-flex align-items-center my-2 gap-3 w-100">
      <div class="d-flex flex-column align-items-start">
        <div class="d-flex align-items-center gap-2 w-100 ">
          <label class="fs-14px">
            Agrega Disney+
            por $2.000
          </label>
          <img *ngIf="producto.variableData.agregado" class="mx-1 rounded-6"
            src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
          <div class="form-check  " *ngIf="producto.variableData.agregado">
            <input class="form-check-input" type="checkbox" (change)="onSelect($event.target.checked)"
              id={{producto.id}}>
          </div>
        </div>
        <div class="d-flex align-items-center gap-3  w-75">
          <span class="fs-14px text-black">Incluye</span>
          <div class="d-flex align-items-center ">

            <img class=" me-2 rounded-6" src="../../../assets/img/Iconos Landing/Icon_star.svg" height="40" width="40">
            <img class=" rounded-6" src="../../../assets/img/maxlogo.png" height="40" width="40">
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-md-column flex-row-reverse align-items-center align-items-md-start justify-content-between">
      <div class="d-flex align-items-center br-15 text-dark borderGreen border border-1 ">
        <span
          class="greenBg text-white br-15 px-md-3 px-2 fs-14px p-1 fw-bold ">{{(producto.variableData.descuento.porcentaje
          ===
          'Web' ? 'DCTO. EXCLUSIVO WEB':producto.variableData.descuento.porcentaje + ' %')}}
        </span>
        <span class="rounded-end px-md-2 px-2 fs-14px fw-bold greenColor "
          *ngIf="producto.variableData.descuento.porcentaje !== 'Web'"> x
          {{producto.variableData.descuento.duracion}}</span>
      </div>
      <div class="">
        <h5 class="fs-42px fw-bolder text-black mt-3"
          *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id) == false">
          {{producto.offerAmount|currencyFormat}}
          <span class="fw-medium fs-20px"
            *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web'">/mes</span>
          <span class="" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
            {{producto.variableData.descuento.duracion}}</span>
        </h5>
        <h5 class="fs-42px fw-bolder text-black mt-3"
          *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id)">
          {{producto.variableData.agregado.offer|currencyFormat}}
          <span class="fw-medium fs-20px"
            *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web'">/mes</span>
          <span class="" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
            {{producto.variableData.descuento.duracion}}</span>
        </h5>

        <h5 class="fs-42px fw-bolder text-black mt-3"
          *ngIf="producto.offerAmount !== null && !producto.variableData.agregado">
          {{producto.offerAmount|currencyFormat}}
          <span class="fw-medium fs-20px"
            *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje !== 'Web'">/mes</span>
          <span class="" *ngIf="producto.category !== '3' && producto.variableData.descuento.porcentaje === 'Web' ">X
            {{producto.variableData.descuento.duracion}}</span>
        </h5>

        <h5 class="fs-6 grayColor" *ngIf="producto.offerAmount === null && !producto.variableData.agregado">
          {{producto.amount |
          currencyFormat}}
          <span class="fs-6" *ngIf="producto.category !== '3'">/mes</span>
        </h5>
        <h6 class="fs-6 grayColor" *ngIf="producto.offerAmount !== null  && !producto.variableData.agregado  "> Luego
          {{producto.amount
          |
          currencyFormat}}
          <span class="fs-6" *ngIf="producto.category !== '3'">/mes</span>
        </h6>

        <h6 class="fs-6 grayColor"
          *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id) == false ">
          Luego {{producto.amount | currencyFormat}}
          <span class="" *ngIf="producto.category !== '3'">/mes</span>
        </h6>
        <h6 class="fs-6 grayColor"
          *ngIf="producto.offerAmount !== null && producto.variableData.agregado && searchActiveCheckbox(producto.id)">
          Luego
          {{producto.variableData.agregado.amount | currencyFormat}}
          <span class="" *ngIf="producto.category !== '3'">/mes</span>
        </h6>
      </div>
    </div>

    <div class="d-flex flex-row-reverse flex-md-column justify-content-between align-items-center w-100">

      <a *ngIf="searchActiveCheckbox(producto.id) == false || !producto.variableData.agregado" [href]="producto.link"
        class="btn btn-hover text-white rounded-7 p-2 my-3 text-capitalize fs-3 fw-bold w-responsive w-100 "
        id={{getId(producto)}}>Ver
        factibilidad
      </a>
      <a *ngIf="searchActiveCheckbox(producto.id) && producto.variableData.agregado"
        [href]="producto.variableData.agregado.utm"
        class="btn btn-hover text-white rounded-7 p-2 my-3 text-capitalize fs-3 fw-bold w-responsive"
        id={{getId(producto)}}>Ver
        factibilidad
      </a>
      <a class="fs-14 d-md-none d-block  fw-bold text-black dropdown-toggle borderGreen border p-1 rounded-7 px-4 text-center"
        data-mdb-toggle="collapse" href="#collapse{{producto.id}}" role="button" aria-expanded="false"
        aria-controls="collapse{{producto.id}}">
        Incluye
      </a>


    </div>



    <div class="d-none d-md-block">
      <div class="row d-flex justify-content-center">
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3">
          <img class="me-2" src="../../../assets/img/Iconos Landing/internet 1.svg" height="40" width="40">
          <p class="m-0 fs-14px">Velocidad Simétrica de bajada y subida: Hasta
            {{producto.variableData.contenido.beneficio}} Mbps.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.instalacion == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg" height="40" width="40">
          <p class="m-0 fs-14px"> Instalacion sin costo</p>
        </div>
        <!-- <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.appfree == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg" height="40" width="40">
          <p class="m-0 fs-14px">APP Smart WiFi</p>
        </div> -->
        <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.integra == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Router 1.svg" height="40" width="40">
          <div>
            <p class="m-0 fs-14px ">Incluye IntegraHub WIFI 6</p>

          </div>
        </div>
        <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.extensor == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/repetidor_azul_x2.svg" height="40" width="40">
          <div>
            <p class="m-0 fs-14px ">Con extensor WIFI</p>
            <p class="m-0 fs-14px grayColor">Si tu hogar lo requiere</p>
          </div>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.canales > 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
          <p class="m-0 fs-14px">{{producto.variableData.contenido.canales}} Canales (75 HD) + 8 abiertos (7 HD)</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.deco == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/decodificador.svg" height="40" width="40">
          <p class="m-0 fs-14px">1 decodificador incluido con Movistar TV</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.minutos == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/telefonoMinutos.svg" height="40" width="40">
          <p class="m-0 fs-14px">Minutos Fijos Ilimitados y 300 minutos a móviles</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.netflix == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40"
            class="rounded-7">
          <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.disney == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
          <p class="m-0 fs-14px"><span class="colorMovistar">Ahora</span> tu plan incluye: Disney+.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.star == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Icon_star.svg" height="40" width="40">
          <p class="m-0 fs-14px"><span class="colorMovistar">Ahora</span> tu plan incluye: Star+.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.paramount == 1">

          <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none">
      <a class="fs-14 mb-2 fw-bold text-black dropdown-toggle" data-mdb-toggle="collapse"
        href="#collapse{{producto.id}}" role="button" aria-expanded="false" aria-controls="collapse{{producto.id}}">
        Incluye
      </a>

      <div id="collapse{{producto.id}}" class="collapse ">
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3">
          <img class="me-2" src="../../../assets/img/Iconos Landing/internet 1.svg" height="40" width="40">
          <p class="m-0 fs-14px">Velocidad Simétrica de bajada y subida: Hasta
            {{producto.variableData.contenido.beneficio}} Mbps.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.instalacion == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg" height="40" width="40">
          <p class="m-0 fs-14px"> Instalacion sin costo</p>
        </div>
        <!-- <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.appfree == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg" height="40" width="40">
          <p class="m-0 fs-14px">APP Smart WiFi</p>
        </div> -->
        <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.integra == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Router 1.svg" height="40" width="40">
          <div>
            <p class="m-0 fs-14px ">Incluye IntegraHub WIFI 6</p>

          </div>
        </div>
        <div class="col-11 p-0 d-flex  justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.extensor == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/repetidor_azul_x2.svg" height="40" width="40">
          <div>
            <p class="m-0 fs-14px ">Con extensor WIFI</p>
            <p class="m-0 fs-14px grayColor">Si tu hogar lo requiere</p>
          </div>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.canales > 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
          <p class="m-0 fs-14px">{{producto.variableData.contenido.canales}} Canales (75 HD) + 8 abiertos (7 HD)</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.deco == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/decodificador.svg" height="40" width="40">
          <p class="m-0 fs-14px">1 decodificador incluido con Movistar TV</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.minutos == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/telefonoMinutos.svg" height="40" width="40">
          <p class="m-0 fs-14px">Minutos Fijos Ilimitados y 300 minutos a móviles</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.netflix == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40"
            class="rounded-7">
          <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.disney == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Icon_disney.svg" height="40" width="40">
          <p class="m-0 fs-14px"><span class="colorMovistar">Ahora</span> tu plan incluye: Disney+.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.star == 1">
          <img class="me-2" src="../../../assets/img/Iconos Landing/Icon_star.svg" height="40" width="40">
          <p class="m-0 fs-14px"><span class="colorMovistar">Ahora</span> tu plan incluye: Star+.</p>
        </div>
        <div class="col-11 p-0 d-flex justify-content-start align-items-center mb-3"
          *ngIf="producto.variableData.contenido.paramount == 1">

          <p class="m-0 fs-14px">Netflix con hasta 2 dispositivos reproduciendo simultaneamente</p>
        </div>
      </div>
    </div>
  </div>
</div>