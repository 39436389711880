import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Caluga } from '../interfaces/caluga';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-caluga',
  templateUrl: './caluga.component.html',
  styleUrls: ['./caluga.component.scss']
})
export class CalugaComponent implements OnInit {

  @Input() producto: Caluga;
  @Input() type: string;
  @Input() cuantity: number;
  urlParams: string;
  @Output() productoSeleccionado: EventEmitter<Caluga> = new EventEmitter<Caluga>();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {


  }

  selectProduct(producto: Caluga) {
    this.productoSeleccionado.emit(producto);
  }



  getNumberGigas(cantidad: number): string {
    let frase = '';
    switch (true) {

      case cantidad < 0:
        frase = 'ilimitados';
        break;

      case cantidad >= 0 && cantidad < 1:
        frase = `${(cantidad * 1000)}`;
        break;

      default:
        frase = `${cantidad}`;
        break;
    }
    return frase;
  }

  getNumberMinutos(cantidad: number): string {
    let frase = '';
    switch (true) {
      case cantidad < 0:
        frase = 'ilimitados';
        break;
      default:
        frase = `${cantidad}`;
        break;
    }
    return frase;
  }

  getNumberSMS(cantidad: number): string {
    let frase = '';
    switch (true) {
      case cantidad < 0:
        frase = 'ilimitados';
        break;
      default:
        frase = `${cantidad}`;
        break;
    }
    return frase;
  }

  getId(producto) {
    let frase = '';
    if (producto.productName.includes('TV') && producto.categoryDescription.includes('Duo')) {
      frase = `Duoplus${producto.variableData.contenido.hasta}`;

    } else if (producto.productName.includes('Star') && producto.productDescription.includes('Duo')) {
      frase = `DuoStar${producto.variableData.contenido.hasta} `;
    }
    else if (producto.productName.includes('Telefonía') && producto.productDescription.includes('Duo')) {
      frase = `DuoFono${producto.variableData.contenido.hasta}`;
    }
    else if (producto.productDescription.includes('Combo+') && producto.productDescription.includes('Trío')) {
      frase = `TrioPlus${producto.variableData.contenido.hasta}`;
    }
    else if (producto.productDescription.includes('Star+') && producto.productDescription.includes('Trío')) {
      frase = `TrioStar${producto.variableData.contenido.hasta}`;
    }
    else if (producto.productDescription.includes('Fibra') && producto.category == '4') {
      frase = `Fibra${producto.variableData.contenido.hasta}`;
    }
    else if (producto.category == "19") {
      frase = producto.productName.trim().replace(/ /g, '');
    }
    else if (producto.category == "20" && producto.productDescription.includes('Duo')) {
      frase = `Duostar${producto.variableData.contenido.hasta}`;
    }

    return frase
  }

  onSelect(state: boolean) {
  }

  searchActiveCheckbox(product: any) {
    let checkbox = document.getElementById(product) as HTMLInputElement;
    if (checkbox?.checked) {
      return true
    } else {
      return false
    }
  }

  calcularPrecioTotal(producto: Caluga, cantidad: number, type: string) {
    if (type == 'offer') {
      if (producto.id == '1') {
        const precio = producto.amount;
        const linesCantidad = 4990 * (cantidad - 1);
        const total = Number(precio) + Number(linesCantidad);
        return this.transform(total);
      } else {

        const precio = producto.amount;
        const linesCantidad = 2990 * (cantidad - 1);
        const total = Number(precio) + Number(linesCantidad);
        return this.transform(total);
      }

    } else if (type == 'plan') {
      const precio = producto.amount;
      const linesCantidad = 9990 * (cantidad - 1);
      const total = Number(precio) + Number(linesCantidad);
      return this.transform(total);

    } else if (type == 'cu') {
      if (producto.id == '1') {
        const precio = producto.amount;
        const linesCantidad = 4990 * (cantidad - 1);
        const total = Number(precio) + Number(linesCantidad);
        const cadaUno = total / cantidad;
        return this.transform(cadaUno);
      } else {
        const precio = producto.amount;
        const linesCantidad = 2990 * (cantidad - 1);
        const total = Number(precio) + Number(linesCantidad);
        const cadaUno = total / cantidad;
        return this.transform(cadaUno);
      }
    }

  }

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == null) {
      return '';
    }
    const rx = /(\d+)(\d{3})/;
    value = parseInt(String(value), 10);
    return String(value).replace(/^\d+/, function (w) {
      while (rx.test(w)) {
        w = w.replace(rx, '$1.$2');
      }
      return '$' + w;
    });
  }






}
